import { simplify } from '@/utils/strings'
import type { Item, CollapsibleItem } from '@/components/DropdownChecklist/types'

export const filterBySearchText = (searchText: string, items: (Item | CollapsibleItem)[]) => {
  return items.filter((item: Item | CollapsibleItem) => {
    const isItemLabelMatchingSelectedText = simplify(item.label).includes(simplify(searchText))

    if (isItemLabelMatchingSelectedText) return true

    if (item.children) {
      return (item as CollapsibleItem).children.some(child => simplify(child.label).includes(simplify(searchText)))
    }

    return false
  })
}
