<script setup lang="ts">
import RoomieRadio from '@lahaus-roomie/roomie/src/components/RoomieRadio/index.vue'

import type { Props } from './types'

defineProps<Props>()
const emit = defineEmits(['update:modelValue'])

const { tm, rt } = useI18n()

const options: any = tm('options')
</script>

<script lang="ts">
export default {
  name: 'DeliveryTermRadioGroup'
}
</script>

<template>
  <ul class="pt-8 pb-24">
    <li>
      <RoomieRadio
        v-for="(option, index) in options"
        :id="`${id}-modal-filters-delivery-term-option-${index}`"
        :key="`${id}-modal-filters-delivery-term-option-${index}`"
        :data-lh-id="`${id}-modal-filters-delivery-term-option-${index}-selected`"
        :label="rt(option.label)"
        :value="rt(option.value)"
        :checked="modelValue ?? ''"
        class="mt-16"
        @change="() => emit('update:modelValue', rt(option.value))" />
    </li>
  </ul>
</template>

<i18n lang="json" src="./i18n.json" />
