import type { Zone } from '@/utils/locations/types'
import type { SearchFilters } from '@/components/SearchFilters/types'
import type { CollapsibleItem } from '@/components/DropdownChecklist/types'
import type { LocationsQueryUtils } from '@/stores/locationsStore/types'

export const parseZonesToItems = (zones: Zone[]): CollapsibleItem[] => {
  return zones.map(({ name, value, neighborhoods }) => {
    const children = neighborhoods
      .map(({ name, value }) => ({ label: name, value }))
      .sort((a, b) => a.label.localeCompare(b.label))

    return { label: name, value, children }
  }).flat()
}

export const getInitialState = (currentFilters: SearchFilters, config: { countryCode: string; enableDefaults: boolean, locationsQueryUtils: LocationsQueryUtils }) => {
  if (currentFilters.businessHubs.length && currentFilters.neighborhoods.length) {
    const zones = config.locationsQueryUtils.getZonesByNeighborhoodsValues(currentFilters.neighborhoods)
    const filteredZones = zones.map(zone => ({ ...zone, neighborhoods: zone.neighborhoods.filter(neighborhood => currentFilters.neighborhoods.includes(neighborhood.value)) }))

    return {
      businessHubs: currentFilters.businessHubs,
      zones: filteredZones
    }
  }

  const hubsByNeighborhoods = config.locationsQueryUtils.getBusinessHubsByNeighborhoodsValues([...currentFilters.neighborhoods]).map(hub => hub.value)

  if (!hubsByNeighborhoods.length && config.enableDefaults) {
    const defaultHubs = {
      co: ['caribe'],
      mx: ['ciudad-de-mexico']
    }[config.countryCode] as string[]

    return {
      businessHubs: defaultHubs,
      zones: config.locationsQueryUtils.getZonesByBusinessHubValue(defaultHubs[0])
    }
  }

  return {
    businessHubs: hubsByNeighborhoods,
    zones: config.locationsQueryUtils.getZonesByNeighborhoodsValues(currentFilters.neighborhoods)
  }
}

export const removeHubZonesFromCurrentSelection = (currentSelection: CollapsibleItem[], hubValue: string, locationsQueryUtils: LocationsQueryUtils) => {
  const zoneValuesByHub = locationsQueryUtils.getZonesByBusinessHubValue(hubValue).map(zone => zone.value)

  return currentSelection.filter(zone => !zoneValuesByHub.includes(zone.value))
}
