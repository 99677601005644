<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import CurrencyInput from '@lahaus-roomie/roomie/src/components/RoomieCurrencyInput/index.vue'
import type { Props } from '@/components/SearchFilters/ModalContent/PriceRange/types'
import { DEFAULT_VALUES } from '@/components/SearchFilters/ModalContent/PriceRange/utils'
import MinMaxRange from '@/components/App/MinMaxRange/index.vue'

defineProps<Props>()

const { t, locale } = useI18n()

const minPrice = defineModel<number | null>('minPrice', { default: null })
const maxPrice = defineModel<number | null>('maxPrice', { default: null })
const isFocusingInputs = ref(false)
const sliderRangesBackup = ref<{ min: number | null, max: number | null }>({ min: 0, max: 0 })

const handleMinMaxChange = (evt: { minValue: number, maxValue: number }) => {
  minPrice.value = evt.minValue
  maxPrice.value = evt.maxValue
}

const handleInputsFocus = () => {
  isFocusingInputs.value = true
  sliderRangesBackup.value.min = minPrice.value
  sliderRangesBackup.value.max = maxPrice.value
}

const handleInputsBlur = () => {
  isFocusingInputs.value = false
}

const defaultRangeValues = computed(() => DEFAULT_VALUES[locale.value as ('co' | 'mx')])

const currencyInputLocale = computed(() => ({ mx: 'es-MX', co: 'es-CO' }[locale.value as ('co' | 'mx')]))

onMounted(() => {
  if (minPrice.value || maxPrice.value) return

  minPrice.value = defaultRangeValues.value.min
  maxPrice.value = defaultRangeValues.value.max
})
</script>

<script lang="ts">
export default {
  name: 'PriceRange'
}
</script>

<template>
  <div class="pb-24">
    <div class="grid grid-cols-2 gap-12 mb-12">
      <CurrencyInput
        :id="`${id}-min-price-input`"
        v-model="minPrice"
        :data-lh-id="`${id}-min-price-input`"
        :label="t('minLabel')"
        :locale="currencyInputLocale"
        autocomplete="off"
        @focus="handleInputsFocus"
        @blur="handleInputsBlur" />

      <CurrencyInput
        :id="`${id}-max-price-input`"
        v-model="maxPrice"
        :data-lh-id="`${id}-max-price-input`"
        :label="t('maxLabel')"
        autocomplete="off"
        :locale="currencyInputLocale"
        @focus="handleInputsFocus"
        @blur="handleInputsBlur" />
    </div>

    <MinMaxRange
      :id="`${id}-range-slider`"
      :initial-min="isFocusingInputs ? sliderRangesBackup.min : minPrice"
      :initial-max="isFocusingInputs ? sliderRangesBackup.max : maxPrice"
      :min-range="defaultRangeValues.min"
      :max-range="defaultRangeValues.max"
      :interval="defaultRangeValues.interval"
      :gap="defaultRangeValues.interval"
      @update:model-value="handleMinMaxChange" />
  </div>
</template>

<i18n lang="json" src="./i18n.json"></i18n>
