<script lang="ts" setup>
import { ref, computed, watch } from 'vue'

import IconChevron from '@lahaus-roomie/roomie/src/assets/icons/chevron-up.svg'
import RoomieCheckbox from '@lahaus-roomie/roomie/src/components/RoomieCheckbox/index.vue'

import type { Props, Item, CollapsibleItem } from './types'

import { filterBySearchText } from './utils'
import DropdownChecklistCollapsibleItem from '@/components/DropdownChecklist/CollapsibleItem/index.vue'

import clickOutside from '@/directives/clickOutside'
import './_index.scss'

const props = withDefaults(defineProps<Props>(), {
  canSelectAll: false
})
const { t } = useI18n()
const { isDesktop } = useDevice()

const selectedItems = defineModel<Item[]>({ required: true })
const areItemsVisible = ref(false)

const searchText = ref('')
const searchInputRef = ref(null)
const isSearchInputFocused = ref(false)

const openItemIndex = ref<number | null>(null)

const toggleItemsList = () => {
  areItemsVisible.value = !areItemsVisible.value
}

const showItemsList = () => {
  if (areItemsVisible.value) return

  areItemsVisible.value = true
}

const handleItemSelect = (isSelected: boolean, item: Item) => {
  if (!isSelected) {
    const itemIndex = selectedItems.value.findIndex(selectedItem => selectedItem.value === item.value)
    selectedItems.value.splice(itemIndex, 1)
    return
  }

  selectedItems.value.push(item)
}

const handleCollapsibleItemSelect = (itemValue: any, valuesSelected: any[]) => {
  const selectedItemIndex = selectedItems.value.findIndex(item => item.value === itemValue)
  const isItemAlreadySelected = selectedItemIndex >= 0

  const itemIndex = props.items.findIndex(item => item.value === itemValue)

  if (itemIndex < 0) return

  const itemData = props.items[itemIndex]
  const selectedChildren = (itemData as CollapsibleItem).children.filter((child: Item) => valuesSelected.includes(child.value))

  if (isItemAlreadySelected) {
    if (!selectedChildren.length) {
      return selectedItems.value.splice(selectedItemIndex, 1)
    }

    (selectedItems.value[selectedItemIndex] as CollapsibleItem).children = selectedChildren
    return
  }

  (selectedItems.value as CollapsibleItem[]).push({ ...itemData, children: selectedChildren })
}

const handleInputBlur = (evt: FocusEvent) => {
  const isBlurredByVoid = evt.relatedTarget === null
  const isBlurredByItem = evt.relatedTarget && evt.relatedTarget.closest('li.dropdown-checklist__check')
  const isBlurredByCleanAllBtn = evt.relatedTarget && evt.relatedTarget.classList.contains('dropdown-checklist__clean-all-btn')

  if (isDesktop && (isBlurredByVoid || isBlurredByItem || isBlurredByCleanAllBtn)) {
    evt.target.focus()
    return
  }

  isSearchInputFocused.value = false
}

const areAllItemsSelected = computed(() => {
  return props.items.length === selectedItems.value.length
})

const filteredItems = computed(() => {
  if (searchText.value === '') return props.items

  return filterBySearchText(searchText.value, props.items)
})

const isSelectedItem = (item: Item) => {
  const selectedItemsValues = selectedItems.value.map(item => item.value)

  return selectedItemsValues.includes(item.value)
}

const getSelectedChildren = (item: CollapsibleItem) => {
  const selectedItem = selectedItems.value.find(selectedItem => selectedItem.value === item.value)

  if (!selectedItem) return []

  return (selectedItem as CollapsibleItem).children.map(child => child.value)
}

const cleanAll = () => {
  searchText.value = ''
  selectedItems.value.splice(0, selectedItems.value.length)
}

const handleSelectAll = () => {
  if (areAllItemsSelected.value) {
    selectedItems.value.splice(0, selectedItems.value.length)
    return
  }

  selectedItems.value.splice(0, selectedItems.value.length, ...props.items)
}

const handleCollapsibleItemOpen = (evt: { index: number, value: any, isOpen: boolean }) => {
  if (!evt.isOpen) {
    openItemIndex.value = null
    return
  }

  openItemIndex.value = evt.index
}

watch(searchText, (newSearchTextValue) => {
  if (!newSearchTextValue || !filteredItems.value.length) {
    openItemIndex.value = null
    return
  }

  openItemIndex.value = 0
}, { flush: 'post' })

const vClickOutside = clickOutside
</script>

<script lang="ts">
export default {
  name: 'DropdownChecklist'
}
</script>

<template>
  <div
    v-click-outside="() => areItemsVisible = false"
    class="dropdown-checklist flex flex-col relative border border-carbon-300 rounded-md">
    <div
      :id="`${id}-dropdown-checklist-items-trigger`"
      class="relative flex items-center gap-8 pl-16">
      <input
        :id="`${id}-dropdown-checklist-search-input`"
        ref="searchInputRef"
        v-model="searchText"
        :data-lh-id="`${id}-dropdown-checklist-search-input`"
        class="dropdown-checklist__search-input py-12 flex-1 outline-none"
        :class="{
          'text-transparent': !isSearchInputFocused,
          'placeholder-transparent': selectedItems.length && !isSearchInputFocused
        }"
        :placeholder="props.placeholder || t('placeholder')"
        autocomplete="off"
        @click="showItemsList"
        @focus="isSearchInputFocused = true"
        @blur="handleInputBlur">

      <div
        v-if="!areItemsVisible"
        class="dropdown-checklist__custom-label absolute top-0 left-6 h-full pointer-events-none flex-1 text-left text-16 leading-normal py-12 bg-white"
        :class="{ 'text-carbon-300': !selectedItems.length }">
        <template v-if="selectedItems.length">
          <template v-if="label">
            {{ label }}
          </template>

          <template v-else>
            {{ t('itemsSelected', { count: selectedItems.length }) }}
          </template>
        </template>
      </div>

      <button
        :id="`${id}-dropdown-checklist-items-toggle`"
        :data-lh-id="`${id}-dropdown-checklist-items-toggle`"
        class="shrink-0 pr-16"
        @click="toggleItemsList">
        <IconChevron
          class="w-24 h-24 fill-brand-800 duration-300"
          :class="{ 'rotate-180': !areItemsVisible }" />
      </button>
    </div>

    <div
      v-if="areItemsVisible"
      class="dropdown-checklist__options-container scale-up-ver-top w-full p-8 rounded-8 border border-carbon-light-300 bg-white absolute top-24 left-0 z-10">
      <div class="flex">
        <p class="flex-1 p-12 text-16">
          {{ t('itemsSelected', { count: selectedItems.length }) }}
        </p>

        <button
          :id="`${id}-dropdown-checklist-clean-all`"
          :data-lh-id="`${id}-dropdown-checklist-clean-all-clicked`"
          class="dropdown-checklist__clean-all-btn text-14 text-primary-600 underline font-medium p-12"
          @click="cleanAll">
          {{ t('cleanAll') }}
        </button>
      </div>

      <ul class="flex flex-col gap-4 max-h-[300px] overflow-y-scroll">
        <li
          v-if="canSelectAll && filteredItems.length"
          class="dropdown-checklist__check ml-8 px-8 py-12 rounded-4 relative duration-200"
          :class="{ 'dropdown-checklist__check--bar': areAllItemsSelected }">
          <RoomieCheckbox
            :id="`${id}-dropdown-checklist-item-select-all`"
            :traking-lh-id="`${id}-dropdown-checklist-item-select-all`"
            :label="t('selectAll')"
            :model-value="areAllItemsSelected"
            @update:model-value="handleSelectAll" />
        </li>

        <li
          v-for="(item, index) in filteredItems"
          :key="item.value"
          class="dropdown-checklist__check ml-8 px-8 rounded-4 relative duration-200"
          :class="{ 'dropdown-checklist__check--bar': isSelectedItem(item) }">
          <DropdownChecklistCollapsibleItem
            v-if="(item as CollapsibleItem).children"
            :id="`${id}-dropdown-checklist-item-${item.value}`"
            :label="item.label"
            :value="getSelectedChildren(item as CollapsibleItem)"
            :children="(item as CollapsibleItem).children"
            :open="index === openItemIndex"
            :children-filter-text="searchText"
            @select="(selectedChildren) => handleCollapsibleItemSelect(item.value, selectedChildren)"
            @collapse-change="(evt) => handleCollapsibleItemOpen({...evt, index})" />

          <RoomieCheckbox
            v-else
            :id="`${id}-dropdown-checklist-item-${item.value}`"
            :traking-lh-id="`${id}-dropdown-checklist-item-${item.value}`"
            :label="item.label"
            :model-value="isSelectedItem(item)"
            @update:model-value="($event) => handleItemSelect($event, item)" />
        </li>
      </ul>
    </div>
  </div>
</template>

<i18n src="./i18n.json" />
