export const DEFAULT_VALUES = {
  co: {
    min: 90_000_000,
    max: 1_600_000_000,
    interval: 50_000_000
  },
  mx: {
    min: 1_000_000,
    max: 18_000_000,
    interval: 200_000
  }
}
