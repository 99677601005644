import zonesData from '@/constants/locations/zones.json'
import { BusinessHub, Neighborhood, Zone } from '@/utils/locations/types'

export const getAllAvailableBusinessHubs = (countryCode: string, locationsData = zonesData): BusinessHub[] => locationsData[countryCode].business_hubs

export const getBusinessHubsByValues = (hubValues: string[], countryCode: string, locationsData = zonesData): BusinessHub[] => {
  return locationsData[countryCode].business_hubs.filter((hub: BusinessHub) => hubValues.includes(hub.value))
}

export const getBusinessHubsByNeighborhoodsValues = (neighborhoodsValues: string[], countryCode = 'mx', locationsData = zonesData): BusinessHub[] => {
  return locationsData[countryCode].business_hubs.filter((hub: BusinessHub) => {
    const neighborhoods = hub.zones.map(zone => zone.neighborhoods).flat()

    return neighborhoods.some(neighborhood => neighborhoodsValues.includes(neighborhood.value))
  })
}

export const getAllAvailableZones = (countryCode: string, locationsData = zonesData): Zone[] => locationsData[countryCode].business_hubs.map(hub => hub.zones).flat()

export const getZonesByBusinessHubName = (hubName: string, countryCode: string, locationsData = zonesData): Zone[] => {
  const hub = locationsData[countryCode].business_hubs.find(hub => hub.name === hubName)

  if (!hub) return []

  return hub.zones
}

export const getZonesByBusinessHubValue = (hubValue: string, countryCode: string, locationsData = zonesData): Zone[] => {
  const hub = locationsData[countryCode].business_hubs.find(hub => hub.value === hubValue)

  if (!hub) return []

  return hub.zones
}

export const getZonesByNeighborhoodsValues = (neighborhoodsValues: string[], countryCode: string, locationsData = zonesData): Zone[] => {
  const allAvailableZones = getAllAvailableZones(countryCode, locationsData)

  return allAvailableZones.filter((zone: Zone) => {
    return zone.neighborhoods.some(neighborhood => neighborhoodsValues.includes(neighborhood.value))
  })
}

export const getBusinessHubZonesByNeighborhoodsValues = (hubValue: string, neighborhoodsValues: string[], countryCode: string, locationsData = zonesData): Zone[] => {
  const hub = locationsData[countryCode].business_hubs.find(hub => hub.value === hubValue)

  if (!hub) return []

  return hub.zones.filter((zone: Zone) => {
    return zone.neighborhoods.some(neighborhood => neighborhoodsValues.includes(neighborhood.value))
  })
}

export const getAllAvailableNeighborhoods = (countryCode: string, locationsData = zonesData): Neighborhood[] => getAllAvailableZones(countryCode, locationsData).map(zone => zone.neighborhoods).flat()

export const getNeighborhoodsByValues = (values: string[], countryCode = 'mx', locationsData): Neighborhood[] => {
  const allAvailableZones = getAllAvailableZones(countryCode, locationsData)

  return allAvailableZones
    .map(zone => zone.neighborhoods)
    .flat()
    .filter(neighborhood => values.includes(neighborhood.value))
}

export const getNeighborhoodsFromBusinessHubValue = (hubValue: string, countryCode: string, locationsData = zonesData): Neighborhood[] => {
  const hub = locationsData[countryCode].business_hubs.find(hub => hub.value === hubValue)

  if (!hub) return []

  return hub.zones.map((zone: Zone) => zone.neighborhoods).flat()
}

export const getNeighborhoodsByZoneValue = (zoneValue: string, countryCode: string, locationsData = zonesData): Neighborhood[] => {
  const allCountryZones = getAllAvailableZones(countryCode, locationsData)
  const zoneData = allCountryZones.find(zone => zone.value === zoneValue)

  if (!zoneData) return []

  return zoneData.neighborhoods
}
