<script lang="ts" setup>
import { computed } from 'vue'
import RoomieCheckbox from '@lahaus-roomie/roomie/src/components/RoomieCheckbox/index.vue'
import IconChevronDown from '@lahaus-roomie/roomie/src/assets/icons/chevron-down.svg'

import type { Props } from './types'
import type { Item } from '@/components/DropdownChecklist/types'
import { simplify } from '@/utils/strings'
import './_index.scss'

const props = withDefaults(defineProps<Props>(), {
  open: false,
  childrenFilterText: ''
})
const emit = defineEmits(['select', 'collapse-change'])

const isHtmlLabel = computed(() => /<\/?[a-z][\s\S]*>/i.test(props.label))

const areAllChildrenSelected = computed(() => {
  return props.children.every(child => props.value.includes(child.value))
})

const filteredChildren = computed(() => {
  const childrenMatching = props.children.filter(child => simplify(child.label).includes(simplify(props.childrenFilterText)))

  if (childrenMatching.length) return childrenMatching

  return props.children
})

const isSelectedItem = (child: Item) => {
  return props.value.includes(child.value)
}

const handleSelectAllChildren = (hasBeenSelected: boolean) => {
  if (!hasBeenSelected) {
    emit('select', [])
    return
  }

  emit('select', props.children.map(child => child.value))
}

const handleChildSelect = (itemValue: any, hasBeenSelected: boolean) => {
  if (!hasBeenSelected) {
    emit('select', props.value.filter(selectedItem => selectedItem !== itemValue))
    return
  }

  emit('select', [...props.value, itemValue])
}
</script>

<script lang="ts">
export default {
  name: 'DropdownChecklistCollapsibleItem'
}
</script>

<template>
  <div class="dropdown-checklist__collapsible-item">
    <div class="flex">
      <RoomieCheckbox
        :id
        :traking-lh-id="id"
        class="dropdown-checklist__collapsible-item-parent flex-1"
        :class="{ 'partial-check': value.length && !areAllChildrenSelected }"
        :model-value="areAllChildrenSelected"
        @update:model-value="handleSelectAllChildren">
        <div class="flex flex-col w-full">
          <div class="flex w-full">
            <p
              v-if="isHtmlLabel"
              v-sanitize="label"
              class="text-carbon-600 flex-1 overflow-hidden"
              :class="{ 'text-carbon-800': value }" />

            <p
              v-else
              class="text-14 text-carbon-600 flex-1 py-12"
              :class="{ 'text-carbon-800': value }">
              {{ label }}
            </p>
          </div>
        </div>
      </RoomieCheckbox>

      <button
        :id="`${id}-collapsible-toggle`"
        :data-lh-id="`${id}-collapsible-toggle`"
        class="px-10"
        @click="() => emit('collapse-change', { value, isOpen: !open })">
        <IconChevronDown
          class="w-24 h-24 fill-primary-600"
          :class="{ 'transform rotate-180': open }" />
      </button>
    </div>

    <ul
      class="dropdown-checklist__collapsible-item__list flex flex-col gap-4"
      :class="{ 'dropdown-checklist__collapsible-item__list--open': open }">
      <li
        v-for="child in filteredChildren"
        :key="child.value"
        class="dropdown-checklist__collapsible-item__list-subitem text-carbon-800 text-12 ml-20">
        <RoomieCheckbox
          :id="`${id}-subitem-${child.value}`"
          :traking-lh-id="`${id}-subitem-${child.value}`"
          :model-value="isSelectedItem(child)"
          @update:model-value="(hasBeenSelected: boolean) => handleChildSelect(child.value, hasBeenSelected)">
          {{ child.label }}
        </RoomieCheckbox>
      </li>
    </ul>
  </div>
</template>
