import type { SearchFilters } from '@/components/SearchFilters/types'
import type { LocationParams } from '@/composables/useListingsSearch/adapters/SearchServiceAdapter/types'
import type { LocationsQueryUtils } from '@/stores/locationsStore/types'

export const parseLocationFiltersToOverride = (data: { locationParams: LocationParams, queryFilters: SearchFilters, countryCode: string }, locationsQueryUtils: LocationsQueryUtils) => {
  const isThereAnyLocationParamActive = Object.values(data.locationParams).some(Boolean)

  if (isThereAnyLocationParamActive) {
    const clusters = data.queryFilters.clusters.length ? data.queryFilters.clusters : []
    const neighborhoods = data.queryFilters.neighborhoods.length ? data.queryFilters.neighborhoods : []
    const businessHubs = data.queryFilters.businessHubs.length ? data.queryFilters.businessHubs : locationsQueryUtils.getBusinessHubsByNeighborhoodsValues(neighborhoods).map(({ value }) => value)

    return ({ businessHubs, clusters, neighborhoods })
  }

  return {}
}

export const extractTruthyFields = (filters: SearchFilters): Partial<SearchFilters> => {
  return Object.fromEntries(
    Object.entries(filters).filter(([_, value]) =>
      value !== null && value !== '' &&
      !(Array.isArray(value) && value.length === 0) &&
      !(Object.prototype.toString.call(value) === '[object Object]' &&
        Object.values(value).filter(a => a).length === 0)
    )
  )
}
