<script lang="ts" setup>
import { ref } from 'vue'

import RoomieButton from '@lahaus-roomie/roomie/src/components/RoomieButton/index.vue'
import RoomieLoader from '@lahaus-roomie/roomie/src/components/RoomieLoader/index.vue'

import type { Props } from './types'

import DeliveryTermRadioGroup from './DeliveryTermRadioGroup/index.vue'
import BedroomsNumberRadioGroup from './BedroomsNumberRadioGroup/index.vue'
import ClustersSelector from '@/components/SearchFilters/ModalContent/ClustersSelector/index.vue'
import PriceRange from '@/components/SearchFilters/ModalContent/PriceRange/index.vue'
import { deepCopy } from '@/utils/objects'
import type { SearchFilters } from '@/components/SearchFilters/types'

const { t } = useI18n()

const props = withDefaults(defineProps<Props>(), {
  initialFilters: () => ({
    businessHubs: [],
    clusters: [],
    neighborhoods: [],
    opportunityType: '',
    deliveryTerm: '',
    bedroomsNumber: null,
    price: {
      min: null,
      max: null
    },
    area: {
      min: null,
      max: null
    },
    q: null
  }),
  enableLocationDefaults: false,
  enablePriceFilter: false
})
const emit = defineEmits(['filters-change'])

const isLoading = ref(false)

const filters = ref<Props['initialFilters']>(deepCopy(props.initialFilters) as SearchFilters)

const onSubmit = () => {
  emit('filters-change', deepCopy(filters.value))
}
</script>

<script lang="ts">
export default {
  name: 'SearchFiltersModalContent'
}
</script>

<template>
  <div class="onboarding-filters__modal-content flex flex-col">
    <h2 class="px-24 pt-10 mt-12 mb-10 text-20 font-semibold">
      {{ t('title') }}
    </h2>

    <div class="flex-1 overflow-y-scroll">
      <div class="px-24">
        <ClustersSelector
          :id="screen"
          v-model="filters"
          :enable-defaults="enableLocationDefaults"
          class="my-24 w-full" />
      </div>

      <hr class="border-carbon-light-50 px-16 md:px-24">

      <div class="mt-24 px-24">
        <h4>
          {{ t('deliveryTermRadioGroup.label') }}
        </h4>

        <DeliveryTermRadioGroup
          :id="screen"
          v-model="filters.deliveryTerm" />
      </div>

      <template v-if="enablePriceFilter">
        <hr class="border-carbon-light-50 px-16 md:px-24">

        <div class="mt-24 px-24">
          <h4 class="mb-10">
            {{ t('priceRange.label') }}
          </h4>

          <PriceRange
            :id="`${screen}-price-range`"
            v-model:min-price="filters.price.min"
            v-model:max-price="filters.price.max" />
        </div>
      </template>

      <hr class="border-carbon-light-50 px-16 md:px-24">

      <div class="mt-24 px-24">
        <h4>
          {{ t('bedroomsNumberRadioGroup.label') }}
        </h4>

        <BedroomsNumberRadioGroup
          :id="screen"
          v-model="filters.bedroomsNumber" />
      </div>
    </div>

    <div class="p-16 border-t border-carbon-light-50">
      <RoomieButton
        :id="`${screen}-button-see-results`"
        :data-lh-id="`${screen}-button-see-results`"
        :aria-label="t('cta.aria_label')"
        class="mt-8 self-center w-full"
        @click="onSubmit">
        <RoomieLoader
          v-if="isLoading"
          class="mr-8 fill-white text-white"
          color="white"
          size="sm" />

        <template v-else>
          {{ t('cta.label') }}
        </template>
      </RoomieButton>
    </div>
  </div>
</template>

<style src="./_index.scss"></style>

<i18n src="./i18n.json"></i18n>
