import { MX_LOCALE } from '@/utils/constants'
import type { LocationItem } from '@/services/listingDataService/types/locations'
import type { BusinessHub } from '@/utils/locations/types'

export const groupLocations = (locations: LocationItem[]) => {
  const groupedLocations: any = {}

  for (const locationItem of locations) {
    const hubValue = locationItem.businessHub.value

    if (!groupedLocations[hubValue]) {
      groupedLocations[hubValue] = {
        ...locationItem.businessHub,
        zones: [locationItem]
      }
    } else {
      groupedLocations[hubValue].zones.push(locationItem)
    }
  }

  for (const hubValue in groupedLocations) {
    const groupedZones: any = {}

    for (const locationItem of groupedLocations[hubValue].zones) {
      const zoneValue = locationItem.zone.value

      if (!groupedZones[zoneValue]) {
        groupedZones[zoneValue] = {
          ...locationItem.zone,
          neighborhoods: [locationItem.neighborhood]
        }
      } else {
        groupedZones[zoneValue].neighborhoods.push(locationItem.neighborhood)
      }
    }

    groupedLocations[hubValue].zones = Object.values(groupedZones)
  }

  return {
    business_hubs: Object.values(groupedLocations) as BusinessHub[]
  }
}

export const applyCustomRules = (locale: string, groupedLocations: { business_hubs: BusinessHub[] }) => {
  if (locale !== MX_LOCALE) return groupedLocations

  const newGroupedLocations = { ...groupedLocations }

  const cdmxIndex = newGroupedLocations.business_hubs.findIndex(hub => hub.value === 'ciudad-de-mexico')

  if (cdmxIndex >= 0) {
    newGroupedLocations.business_hubs[cdmxIndex].alias = 'CDMX'
  }

  const jaliscoIndex = newGroupedLocations.business_hubs.findIndex(hub => hub.value === 'jalisco')

  if (jaliscoIndex >= 0) {
    newGroupedLocations.business_hubs[jaliscoIndex].alias = 'Z.M de Guadalajara'

    const mazatlanZoneIndex = newGroupedLocations.business_hubs[jaliscoIndex].zones.findIndex(zone => zone.value === 'mazatlan')

    newGroupedLocations.business_hubs.push({
      name: 'Jalisco',
      value: 'mazatlan',
      alias: 'Mazatlán',
      code: newGroupedLocations.business_hubs[jaliscoIndex].code,
      zones: newGroupedLocations.business_hubs[jaliscoIndex].zones.splice(mazatlanZoneIndex, 1)
    })

    const nuevoVallartaZoneIndex = newGroupedLocations.business_hubs[jaliscoIndex].zones.findIndex(zone => zone.value === 'nuevo-vallarta')

    if (nuevoVallartaZoneIndex >= 0) {
      newGroupedLocations.business_hubs.push({
        name: 'Jalisco',
        value: 'riviera-nayarit',
        alias: 'Riviera Nayarit',
        code: newGroupedLocations.business_hubs[jaliscoIndex].code,
        zones: newGroupedLocations.business_hubs[jaliscoIndex].zones.splice(nuevoVallartaZoneIndex, 1)
      })
    }
  }

  return newGroupedLocations
}
