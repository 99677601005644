import type { Cluster, ClustersDictionary, Neighborhood } from './types'
import { SearchFilters } from '@/components/SearchFilters/types'
import countriesBusinessHubsData from '@/constants/locations/clusters.json'

export const getAllAvailableBusinessHubsNames = (countryCode = 'mx'): string[] => {
  const businessHubsData = countriesBusinessHubsData[countryCode]
  return Object.keys(businessHubsData as ClustersDictionary)
}

export const getBusinessHubsFromClustersNames = (clustersNames: string[], countryCode = 'mx') => {
  const businessHubsData = countriesBusinessHubsData[countryCode]
  return Object.keys(businessHubsData).filter(hubName => businessHubsData[hubName].some((cluster: Cluster) => clustersNames.includes(cluster.name)))
}

export const getBusinessHubsFromClustersValues = (clustersValues: string[], countryCode = 'mx') => {
  if (!clustersValues) return []

  const businessHubsData = countriesBusinessHubsData[countryCode]
  return Object.keys(businessHubsData).filter(hubName => businessHubsData[hubName].some((cluster: Cluster) => clustersValues.includes(cluster.value)))
}

export const getBusinessHubsFromNeighborhoodsValues = (neighborhoodsValues: string[], countryCode = 'mx') => {
  const businessHubsData = countriesBusinessHubsData[countryCode]
  return Object.keys(businessHubsData).filter(hubName => businessHubsData[hubName].some((cluster: Cluster) => cluster.neighborhoods.some(neighborhood => neighborhoodsValues.includes(neighborhood.value))))
}

export const getAllAvailableClusters = (countryCode = 'mx'): Cluster[] => {
  const businessHubsData = countriesBusinessHubsData[countryCode]
  return Object.values(businessHubsData as ClustersDictionary).flat()
}

export const getClusterByValue = (value: string, countryCode = 'mx'): Cluster | null => {
  const clusters = getAllAvailableClusters(countryCode)

  return clusters.find(cluster => cluster.value === value) ?? null
}

export const getClustersByValue = (values: string[], countryCode = 'mx'): Cluster[] => {
  const clusters = getAllAvailableClusters(countryCode)

  return clusters.filter(cluster => values.includes(cluster.value))
}

export const getClustersByName = (names: string[], countryCode = 'mx'): Cluster[] => {
  const clusters = getAllAvailableClusters(countryCode)

  return clusters.filter(cluster => names.includes(cluster.name))
}

export const getAllClustersByBusinessHubName = (hubName: string, countryCode = 'mx'): Cluster[] => {
  const businessHubsData = countriesBusinessHubsData[countryCode]

  if (!businessHubsData[hubName]) return []

  return businessHubsData[hubName]
}

export const getClustersByNeighborhoodsValues = (neighborhoodsValues: string[], countryCode = 'mx'): Cluster[] => {
  const allAvailableClusters = getAllAvailableClusters(countryCode)

  return allAvailableClusters.filter(cluster => cluster.neighborhoods.some(neighborhood => neighborhoodsValues.includes(neighborhood.value)))
}

export const getAllNeighborhoodsByBusinessHubName = (hubName: string, countryCode = 'mx'): Neighborhood[] => {
  const businessHubsData = countriesBusinessHubsData[countryCode]

  if (!businessHubsData[hubName]) return []

  return businessHubsData[hubName].map((cluster: Cluster) => cluster.neighborhoods).flat()
}

export const getNeighborhoodsFromClustersNames = (clustersNames: string[], countryCode = 'mx'): Neighborhood[] => {
  const allAvailableClusters = getAllAvailableClusters(countryCode)

  return allAvailableClusters
    .filter(cluster => clustersNames.includes(cluster.name))
    .map(cluster => cluster.neighborhoods)
    .flat()
}

export const getNeighborhoodsByValues = (values: string[], countryCode = 'mx'): Neighborhood[] => {
  const allAvailableClusters = getAllAvailableClusters(countryCode)

  return allAvailableClusters
    .map(cluster => cluster.neighborhoods)
    .flat()
    .filter(neighborhood => values.includes(neighborhood.value))
}

export const getNeighborhoodsFromClustersValues = (clustersValues: string[], countryCode = 'mx'): Neighborhood[] => {
  if (!clustersValues) return []

  const allAvailableClusters = getAllAvailableClusters(countryCode)

  return allAvailableClusters
    .filter(cluster => clustersValues.includes(cluster.value))
    .map(cluster => cluster.neighborhoods)
    .flat()
}

export const getNeighborhoodsCountByHubs = (neighborhoodsValues: string[], countryCode = 'mx') => {
  const businessHubsNames = getBusinessHubsFromNeighborhoodsValues(neighborhoodsValues, countryCode)

  const countDict: { [key: string]: number } = {}
  for (const hubName of businessHubsNames) {
    const allNeighborhoodsByHub = getAllNeighborhoodsByBusinessHubName(hubName, countryCode)
    countDict[hubName] = allNeighborhoodsByHub.filter(neighborhood => neighborhoodsValues.includes(neighborhood.value)).length
  }

  return countDict
}

export const getProductType = (property: string) => {
  const propertyMap: Record<string, string> = {
    departamentos: 'Apartamento',
    'desarrollos-inmobiliarios': null,
    casas: 'Casa'
  }

  return propertyMap[property]
}

export const sortByLocation = (location: string, a: string, b: string) => {
  if (a === location && b !== location) return -1
  else if (b === location && a !== location) return 1
  else return 0
}

export const getLocationTitle = (customTitle: string, currentFilters: SearchFilters, t, countryCode = 'mx') => {
  if (customTitle && customTitle !== 'null') return customTitle

  const locationsList = []

  if (currentFilters.neighborhoods && currentFilters.neighborhoods.length > 0) {
    locationsList.push(...getNeighborhoodsByValues(currentFilters.neighborhoods, countryCode).map(neighborhood => neighborhood.name))
  } else if (currentFilters.clusters && currentFilters.clusters.length > 0) {
    locationsList.push(...getClustersByValue(currentFilters.clusters, countryCode).map(cluster => cluster.name))
  } else {
    locationsList.push(...getAllAvailableBusinessHubsNames(countryCode))
  }

  const firstLocationsNames = locationsList.slice(0, locationsList.length - 1)
  const lastLocationName = locationsList[locationsList.length - 1]

  return firstLocationsNames.length > 0
    ? firstLocationsNames.join(', ') + ` ${t('and')} ${lastLocationName}`
    : lastLocationName
}
