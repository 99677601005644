<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import RoomieChipSelector from '@lahaus-roomie/roomie/src/components/RoomieChipSelector/index.vue'
import type { Props } from './types'
import { getInitialState, parseZonesToItems, removeHubZonesFromCurrentSelection } from './utils'
import type { SearchFilters } from '@/components/SearchFilters/types'
import AppSlider from '@/components/AppSlider/index.vue'
import type { CollapsibleItem } from '@/components/DropdownChecklist/types'
import type { BusinessHub } from '@/utils/locations/types'
import DropdownChecklist from '@/components/DropdownChecklist/index.vue'
import { deepCopy } from '@/utils/objects'
import { useLocationsStore } from '@/stores/locationsStore'

import './_index.scss'

const props = withDefaults(defineProps<Props>(), {
  enableDefaults: false
})
const model = defineModel<SearchFilters>({ required: true })

const { t, locale } = useI18n()

const locationsStore = useLocationsStore()

const availableHubs = locationsStore.locationsQueryUtils.getAllAvailableBusinessHubs()

const selectedHubs = ref<string[]>([])
const selectedZones = ref<CollapsibleItem[]>([])
const areThereSelectedHubs = computed(() => selectedHubs.value.length > 0)

const handleHubClick = (hub: BusinessHub) => {
  if (selectedHubs.value.includes(hub.value)) {
    const hubIndex = selectedHubs.value.indexOf(hub.value)
    selectedHubs.value.splice(hubIndex, 1)
    selectedZones.value = removeHubZonesFromCurrentSelection(selectedZones.value, hub.value, locationsStore.locationsQueryUtils)
    return
  }

  const hubZones = parseZonesToItems(locationsStore.locationsQueryUtils.getZonesByBusinessHubValue(hub.value))
  selectedHubs.value.push(hub.value)
  selectedZones.value.push(...hubZones)
}

const itemsBySelectedHubs = computed(() => {
  return parseZonesToItems(selectedHubs.value.map(hubValue => locationsStore.locationsQueryUtils.getZonesByBusinessHubValue(hubValue)).flat())
})

const zoneItemsToShow = computed(() => itemsBySelectedHubs.value.map(({ label, value, children }) => {
  const translatedLabel = `
    <div class="py-8 w-full">
      <p class="text-14 truncate">${label}</p>
      <span class="text-12">(${t('clustersSelector.neighborhoodsCount', { count: children.length }, children.length)})</span>
    </div>
  `

  return ({ label: translatedLabel, value, children })
}))

onMounted(() => {
  const initialState = getInitialState(model.value, {
    countryCode: locale.value,
    enableDefaults: props.enableDefaults,
    locationsQueryUtils: locationsStore.locationsQueryUtils
  })

  selectedHubs.value = deepCopy(initialState.businessHubs) as string[]
  selectedZones.value = parseZonesToItems(initialState.zones) as CollapsibleItem[]
})

watch(selectedHubs, (newHubs) => {
  model.value.businessHubs.splice(0, model.value.businessHubs.length, ...newHubs)
  model.value.clusters = []
}, { deep: true })

watch(selectedZones, (newValue) => {
  const allNeighborhoodValues = newValue.map(({ children }) => children.map(({ value }) => value)).flat()
  model.value.neighborhoods.splice(0, model.value.neighborhoods.length, ...allNeighborhoodValues)
  model.value.clusters = []
}, { deep: true })
</script>

<script lang="ts">
export default {
  name: 'ClustersSelector'
}
</script>

<template>
  <div class="clusters-selector">
    <h4 class="mb-8">
      {{ t('businessHubSelector.label') }}
    </h4>

    <AppSlider
      :id="`${id}-hub-chips-slider`"
      class="w-full"
      lateral-paddings="hidden"
      :padding-right="true">
      <RoomieChipSelector
        v-for="(hub, index) in availableHubs"
        :id="`${id}-hub-chip-${index}`"
        :key="hub.value"
        class="my-4"
        :data-lh-id="`${id}-hub-chip-${index}-clicked`"
        :is-selected="selectedHubs.includes(hub.value)"
        @click="handleHubClick(hub)">
        <template #content>
          <span class="text-16 whitespace-nowrap pointer-events-none">
            {{ hub.alias || hub.name }}
          </span>
        </template>
      </RoomieChipSelector>
    </AppSlider>

    <div
      v-if="areThereSelectedHubs"
      class="clusters-selector__dropdowns-group mt-16">
      <h4 class="mb-8">
        {{ t('clustersSelector.label') }}
      </h4>

      <DropdownChecklist
        :id="`${id}-clusters-selector`"
        v-model="selectedZones"
        :label="t('clustersSelector.selectedLabel', { count: selectedZones.length })"
        :placeholder="t('clustersSelector.placeholder')"
        :items="zoneItemsToShow"
        can-select-all
        class="w-full" />
    </div>
  </div>
</template>

<i18n src="./i18n.json" />
