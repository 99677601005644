import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { parseSearchFilters, convertSearchFiltersToQueryObject } from '@/components/SearchFilters/utils'
import { extractTruthyFields, parseLocationFiltersToOverride } from '@/stores/searchFiltersStore/utils'
import type { SearchFilters } from '@/components/SearchFilters/types'
import { useLocationsStore } from '@/stores/locationsStore'

export const SEARCH_FILTERS_COOKIE_KEY = 'search_filters_v4'

export const useSearchFiltersStore = defineStore('searchFilters', () => {
  const { locale } = useI18n()
  const route = useRoute()
  const locationsStore = useLocationsStore()

  const cacheRefresh = computed(() => route.query.refresh === 'true' || undefined)

  const initialFilters: SearchFilters = {
    businessHubs: [],
    clusters: [],
    neighborhoods: [],
    opportunityType: null,
    deliveryTerm: null,
    bedroomsNumber: null,
    price: { min: null, max: null },
    q: null,
    area: { min: null, max: null },
    ids: []
  }

  const cachedFilters = useCookie<SearchFilters>(SEARCH_FILTERS_COOKIE_KEY, {
    maxAge: 60 * 60 * 24 * 30,
    default: () => initialFilters
  })

  const queryFilters = parseSearchFilters({
    clusters: route.query.clusters as (string | null),
    neighborhoods: route.query.neighborhoods as (string | null),
    deliveryTerm: route.query.delivery_term as (string | null),
    bedroomsNumber: route.query.bedrooms_number as (string | null),
    price: route.query.price as (string | null),
    q: route.query.q as (string | null),
    area: route.query.area as (string | null),
    ids: route.query.ids as (string | null)
  }, locale.value, locationsStore.locationsQueryUtils)

  const truthyQueryFilters = extractTruthyFields(queryFilters)
  const isThereAnyQueryFilter = Object.keys(truthyQueryFilters).length > 0

  if (isThereAnyQueryFilter) {
    cachedFilters.value = {
      ...cachedFilters.value,
      ...truthyQueryFilters
    }
  }

  const locationParams = computed(() => ({
    citySlug: route.params.city as string,
    zoneSlug: route.params.zone as string,
    neighborhoodSlug: route.params.neighborhood as string
  }))

  const isThereAnyLocationParam = computed(() => Object.values(locationParams.value).some(Boolean))

  const currentFilters = ref<SearchFilters>({
    ...cachedFilters.value,
    ...parseLocationFiltersToOverride({ locationParams: locationParams.value, queryFilters, countryCode: locale.value }, locationsStore.locationsQueryUtils)
  })

  const updateFilters = async (filters: SearchFilters) => {
    await setQueryParams(filters)
    currentFilters.value = filters
    cachedFilters.value = filters
  }

  const setQueryParams = async (newFilters: SearchFilters) => {
    const newQuery: any = {
      bedrooms_number: undefined,
      delivery_term: undefined,
      neighborhoods: undefined,
      ...convertSearchFiltersToQueryObject(newFilters),
      business_hubs: undefined,
      clusters: undefined
    }

    await navigateTo({
      path: route.path,
      query: {
        ...route.query,
        ...newQuery
      }
    })
  }

  return {
    currentFilters,
    locationParams,
    isThereAnyLocationParam,
    cacheRefresh,
    updateFilters
  }
})
